/* Button styles to match theme colors and typography */
._1n3pr300, ._1n3pr301, ._1n3pr302, ._1n3pr303 {
    font-family: "Anton", "Geometric", "Segoe UI", "Helvetica Neue", "Arial", "sans-serif", "Red Hat Text";
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center; /* Center content within buttons */
    padding: 13px 21px;
    cursor: pointer;
    text-align: left;
    border: none;
    box-sizing: border-box;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* Primary button style - Auburn Red background with Pure White text */
._1n3pr300 {
    background-color: #A52A2A; 
    color: #FFFFFF; 
}

/* Secondary button style - Monochrome Grey background with Charcoal Black text */
._1n3pr301 {
    background-color: #808080; 
    color: #36454F; 
}

/* Error state button style - No change, standard red color for errors */
._1n3pr302 {
    background-color: #F44336; 
    color: #FFFFFF; 
    border: 1px solid #F44336;
}

/* Upcoming event button style - Bright Yellow background with Off-White text */
._1n3pr303 {
    background-color: #FFD700; 
    color: #F8F8FF; 
}
.error-message {
    max-width: 60%; /* Limits the width to 60% of the screen width */
    margin: 0 auto; /* Centers the bar horizontally */
    background-color: red; /* Background color for visibility */
    color: white; /* Text color */
    padding: 10px; /* Padding inside the bar */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Optional: adds shadow for better visibility */
    text-align: center; /* Centers the text inside the bar */
    border-radius: 5px; /* Optional: rounds the corners */
    position: absolute; /* Keeps the bar at a specific position */
    top: 10%; /* Distance from the top of the screen */
    left: 50%; /* Centers based on the full width */
    transform: translateX(-50%); /* Correctly centers the div */
}
/* General container style - Adjusted for Off-White background */
._1n3pr304 {
    border-color: #e5e7eb55;
    background-color: #F8F8FF;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    align-items: center; /* Centered content */
    justify-content: center; /* Centered content vertically and horizontally */
}

/* Non-selectable items */
._1n3pr305 {
    -webkit-user-select: none;
    user-select: none;
}
.overlay {
    position: fixed; /* or 'absolute' if you need it relative to a container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    z-index: 1000; /* Ensures it stays on top */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Relative positioning container */
._1n3pr306 {
    position: relative;
    display: flex;
    justify-content: center; /* Center content */
    align-items: center; /* Center vertically */
    height: 0;
}

/* Specific item styles */
._1rbg3ua0 {
    margin-top: 12px;
    font-weight: 400;
    box-shadow: 0 6px 12px #0000001f;
    display: flex;
    justify-content: center; /* Center content */
    align-items: center; /* Center vertically */
}

/* Items styled with Charcoal Black color */
._1rbg3ua1, ._1rbg3ua3, ._16wbil71 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #36454F; /* Charcoal Black for text */
}

._1rbg3ua2 {
    object-fit: cover;
    width: 28px;
    height: 28px;
    border-radius: 28px;
}

/* Styles for items in the menu, using Pale Flesh for accents */
._16wbil70, ._16wbil71 {
    font-family: "Red Hat Text";
    color: #FFD8C0; /* Pale Flesh for accents */
    font-weight: 400;
}

/* Modal overlay - Slightly darker to match the theme */
.nao3n42 {
    background: rgba(74, 233, 25, 0.45); /* Slightly darker for better visibility */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    animation: nao3n41 .15s ease;
    bottom: -200px;
    left: -200px;
    padding: 200px;
    right: -200px;
    top: -200px;
    transform: translateZ(0);
    z-index: 999999999;
}

/* Modal container - No color change required */
.nao3n43 {
    display: flex;
    flex-direction: row;
    position: relative;
    animation: nao3n40 .35s cubic-bezier(.15,1.15,.6,1), nao3n41 .15s ease;
    max-width: 100vw;
    align-items: center; /* Center content */
}

/* Keyframes for animations - No color change required */
@keyframes nao3n40 {
    0% { transform: translateX(100%) }
    to { transform: translateY(0) }
}

@keyframes nao3n41 {
    0% { opacity: 0 }
    to { opacity: 1 }
}